/**
* Charts API
* using axios https://github.com/mzabriskie/axios
*/
import { instance } from './utils/authRequest'
import {
  massageTheoreticalData,
  massageHistoricalData
} from '@/utils/backendShared'

const noAuthRequest = instance()

export default {
  // TODO: add timers or check chrome profiling
  async getHistoricalData({loc, sdate, edate}) {
    if (!loc || !sdate || !edate) throw new Error('Missing correct params in getHistoricalData api call')

    const response = await noAuthRequest({
      url: '/rcc-acis/historical-data',
      method: 'post',
      data: { loc, sdate, edate, asQuantiles: true }
    })
    
    return massageHistoricalData(response.data)
  },

  async getTheoreticalData({ loc, sdate, edate }) {
    if (!loc || !sdate || !edate) throw new Error('Missing correct params in getTheoreticalData api call')
    
    // get theoretical data and then passes it to massage util function
    const response = await noAuthRequest({
      url: '/rcc-acis/estimated-data',
      method: 'post',
      data: { loc, sdate, edate }
    })

    return massageTheoreticalData(response.data)
  }
}
